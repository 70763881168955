(function ($) {
  $.fn.selectpicker.defaults = {
    noneSelectedText: 'Nenhum seleccionado',
    noneResultsText: 'Sem resultados contendo {0}',
    countSelectedText: 'Selecionado {0} de {1}',
    maxOptionsText: ['Limite ultrapassado (máx. {n} {var})', 'Limite de seleções ultrapassado (máx. {n} {var})', ['itens', 'item']],
    multipleSeparator: ', ',
    selectAllText: 'Selecionar Tudo',
    deselectAllText: 'Desmarcar Todos'
  };
})(jQuery);
